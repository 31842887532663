<!-- Compoenent actions  -->
<template>
  <div class="">
    <div class="mb-2 text-grey badge-list d-flex justify-content-end">
      <div class="badge badge-title">{{ $t(module + '.gantt.render.render_title') }} :</div>
      <div
        :key="renderkey"
        :class="{
          'badge cursor-pointer': true,
          'text-primary': renderkey !== $store.state.actionsboard.filters.activeGanttRender,
          'badge-secondary text-white': renderkey === $store.state.actionsboard.filters.activeGanttRender,
          ['badge-'+render]: true
        }"
        v-for="(render, renderkey) in renders"
        @click="setRender(renderkey)"
      >
        {{ render }}
      </div>
    </div>
    <div id="gantt" class="card">
      <div ref="gantt"></div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import {ACTION_REQUEST_ACTIONS_UPDATE, ACTION_REQUEST_ACTIONS_UPDATE_DATE} from '../../mutations-types'
import sousComponents from './sousComponents'
import Gantt from 'frappe-gantt'
/**
 * @group _ Module Actionsboard
 * This component display all actions list
 */
  export default {
    name: 'TodoActionsGantt',
    components: {
      // Pdca: sousComponents.Pdca,
    },
    props: ['actions', 'priorities', 'pdca_values', 'users'],
    data () {
      return {
        gantt: null,
        module: 'modules.'+this.$route.meta.moduleName,
        filters: [],
        renders: {
          Day: this.$t('modules.'+this.$route.meta.moduleName + '.gantt.render.render_day'),
          Week: this.$t('modules.'+this.$route.meta.moduleName + '.gantt.render.render_week'),
          Month: this.$t('modules.'+this.$route.meta.moduleName + '.gantt.render.render_month'),
        },
        worldId: this.$store.getters['auth/getWorld'].id,
        selectedTaskIs : {},
      }
    },
    mounted () {
      this.initGantt()
      this.setRender(this.$store.state.actionsboard.filters.activeGanttRender)
    },
    methods: {
      eventClick(id) {
        this.selectTaskDetails(this.actions.find(e => e.id == id))
      },
      eventResize(id, start, end) {
        this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE_DATE, {
          id: this.$route.params.world,
          entity: 'Actions',
          action: {
            id: id,
            startDate: moment(start).format(),
            endDate: moment(end).subtract('day', 1).format(),
          },
        }).then((resp) => {
          this.$store.commit('actionsboard/refreshTodo', {
            id: id,
            key: 'startDate',
            value: moment(start).format(),
          })
          this.$store.commit('actionsboard/refreshTodo', {
            id: id,
            key: 'endDate',
            value: moment(end).subtract('day', 1).format(),
          })
        })
        this.unselectedTask()
      },
      // eventRender(info){
      //   var node = document.createElement('span')
      //   node.setAttribute('class', 'fc-draggable fc-day-grid-even fc-start fc-end d-block small font-weight-bold cursor-pointer');
      //   node.setAttribute('style', 'padding: 2px 3px; background: #F5F5F5; border: 1px solid rgba(0,0,0,.05); border-radius: 4px');
      //   let eventClass = info.event.extendedProps.done ? 'text-striked' : ''
      //   node.innerHTML = ''
      //   node.innerHTML+= `<i class="icon-primitive-dot mr-1 text-${this.priorities[info.event.extendedProps.priority].class}"></i>`
      //   node.innerHTML+= `<span class="word-break ${eventClass}">${info.event.title}</span>`
      //   return node
      // },


      initGantt () {
        this.gantt = new Gantt(this.$refs.gantt, this.actionsFormatted, {
          custom_popup_html: () => { return '' },
          on_click: (task) => {
            this.eventClick(task.id)
          },
          on_date_change: (task, start, end) => {
            this.eventResize(task.id, start, end)
          },
          on_progress_change: (task, progress) => {
            this.progressTask(task.id, progress)
          },
          on_view_change: (mode) => {
          }
        });
      },


      updateTaskStatus (id, status) {
        this.actions.find(b => b.id === Number(id)).status = status;
        this.pdcaTask(this.actions.find(b => b.id === Number(id)), status)
      },
      /**
       * @vuese
       * Select Pdca Action
       * @arg object
       */
      pdcaTask (task, pdca) {
        task.pdca = pdca
        let object = {
          key: 'pdca',
          value: task.pdca,
          id: task.id
        }
        this.UpdateElementTod(object)
      },
      /**
       * @vuese
       * Change progress Action
       * @arg object
       */
      progressTask (taskId, progress) {
        let object = {
          key: 'progress',
          value: progress,
          id: taskId
        }
        this.UpdateElementTod(object)
      },
      /**
       * @vuese
       * fubction to update action item object
       * @arg object
       */
      UpdateElementTod (object) {
        this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE, {id: this.worldId, entity: 'Actions', action: object}).then((resp) => {
          this.$store.commit('actionsboard/refreshTodo', object)
        })
      },
      /**
       * @vuese
       * this function to show detail action selected
       * @arg
       */
      selectTaskDetails (task) {
        let app = this
        if (!(typeof this.selectedTaskIs.id !== 'undefined' && this.selectedTaskIs.id == task.id)) {
          this.selectedTaskIs = task
          this.$emit('onShowDetailTodo', {}, false)
          setTimeout(() => {
            this.$emit('onShowDetailTodo', task, true)
          }, 10)
        }
      },
      //set New DAte Update
      /**
       * @vuese
       * this function change dateStart for action
       * @arg
       */
      chanegDate(elem) {
        this.data.filter(item => {
          if (item.id === elem.id) {
            item.startDate = elem.startDate
          }
        })
      },
      setRender (render) {
        this.gantt.change_view_mode(render)
        this.$store.state.actionsboard.filters.activeGanttRender = render
      }
    },
    computed: {
      actionsFormatted () {
        let actions = []
        this.actions.forEach(elem => {
          if (elem.startDate && elem.endDate) {
            let action = {
              id: elem.id.toString(),
              name: elem.name,
              start: moment(elem.startDate).format("YYYY-MM-DD"),
              end: moment(elem.endDate).format("YYYY-MM-DD"),
              progress: elem.progress || 1,
              // extendedProps: {
              //   id: elem.id,
              //   status: elem.pdca || 'plan',
              //   priority: elem.priority || null,
              //   done: elem.done,
              //   owner: this.users.find(user => { return user.id == elem.owner }),
              // },
            }
            actions.push(action)
          }
        })
        return actions
      }
    },
    watch: {
      actions: {
        deep: true,
        handler (val, oldVal) {
          if (this.gantt) {
            this.gantt.refresh(this.actionsFormatted)
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

#gantt {
  overflow: hidden;
  ::v-deep > div {
    background: white;
    margin: -1px 0px -2px 0px;
    position: relative;
    left: -3px;
    width: calc(100% + 5px) !important;
    .today-highlight {
      fill: rgba(green, .2);
    }
  }
}

::v-deep .tick {
  stroke: #000;
}
::v-deep .text-striked {
  text-decoration:line-through;
}

::v-deep .gantt-container {
  max-width: 100%;
}

::v-deep .gantt {
}
</style>
